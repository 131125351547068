<template>
  <v-card
    rounded="xl"
    class="fill-height"
    flat
    :class="page < 2 ? 'modalStyle' : ''"
  >
    <v-row
      id="painPersonPage"
      v-if="page === 0"
      :class="highZoom ? 'contentStyleHighZoom' : 'contentStyle'"
      justify="center"
      no-gutters
    >
      <v-col
        id="painPersonHeading"
        cols="10"
        :lg="highZoom ? '11' : '8'"
        :class="titleTextClass"
        class="my-4 text-center"
        >{{ $t("painCheckin.pages.0.heading") }}</v-col
      >
      <v-col
        id="painPersonInstructions"
        cols="10"
        :lg="highZoom ? '11' : '8'"
        :class="subTitleTextClass"
        class="vitrueDarkGrey--text text-center"
        >{{
          $t("painCheckin.pages.0.instructions", {
            0: existingAreaText
          })
        }}</v-col
      >

      <v-col cols="10" class="my-4"
        ><v-row justify="center" align="center" no-gutters>
          <v-col cols="4" order="0"> </v-col>
          <v-col
            cols="6"
            md="4"
            :order="$vuetify.breakpoint.mdAndUp ? '1' : '2'"
            ><v-row justify="center" no-gutters
              ><v-chip
                id="painAreaChip"
                v-if="this.svgPainAreaName"
                color="vitrueDarkGrey"
                class="text-body-1 ma-2 white--text"
                :small="!highZoom"
                :x-small="highZoom"
                >{{ svgPainAreaName }}</v-chip
              ></v-row
            ></v-col
          >
          <v-col
            cols="12"
            md="4"
            :order="$vuetify.breakpoint.mdAndUp ? '2' : '1'"
            ><v-row
              :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'"
              no-gutters
              ><v-btn
                class="elevation-0 primary--text text-body-1"
                :small="!highZoom"
                :x-small="highZoom"
                tile
                color="selectedPrimary"
                :outlined="results.pain.gender !== 'male'"
                @click="results.pain.gender = 'male'"
                >{{ $t("painCheckin.pages.0.masculine") }}</v-btn
              ><v-btn
                class="elevation-0 primary--text text-body-1"
                :small="!highZoom"
                :x-small="highZoom"
                tile
                color="selectedPrimary"
                :outlined="results.pain.gender !== 'female'"
                @click="results.pain.gender = 'female'"
                >{{ $t("painCheckin.pages.0.feminine") }}</v-btn
              ></v-row
            ></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="10"
        ><v-row justify="center" no-gutters>
          <v-col xs="8" sm="6" md="3" lg="3">
            <pain-person
              ref="painPerson"
              oneAreaOnly
              hideChips
              hideGenderChange
              v-model="results.pain"
              :selectedColor="$vuetify.theme.currentTheme.brightError"
              @genderChanged="results.pain.gender = $event"
              @selectionChanged="selectedPainArea = $event"
              @selectionNameChanged="svgPainAreaName = $event"
              assessmentType="PainCheckIn"
          /></v-col>
        </v-row>
      </v-col>
    </v-row>
    <div
      id="questionPage"
      v-if="page === 1"
      :class="highZoom ? 'contentStyleHighZoom' : 'contentStyle'"
    >
      <v-row justify="center" align="start" no-gutters>
        <v-col cols="10" class="mt-4 text-center" :class="titleTextClass">
          <v-row justify="center" align="center"
            ><v-col cols="auto">
              <v-img
                id="painQuestionCircleImage"
                class="rounded-circle"
                width="60"
                height="60"
                :src="require(`@/assets/images/${painAreaImage}`)"
              >
                <div class="circle" />
              </v-img>
            </v-col>
            <v-col id="painQuestionHeading" cols="auto">{{
              $t("painCheckin.pages.1.heading", {
                0: painAreaText
              })
            }}</v-col>
          </v-row>
        </v-col>
        <v-col
          cols="10"
          :md="highZoom ? '10' : '8'"
          lg="8"
          :class="$vuetify.breakpoint.lgAndUp ? 'pl-16 ml-16' : ''"
        >
          <v-row justify="center">
            <v-col cols="11"
              ><v-form ref="form" onsubmit="return false;">
                <form-generator
                  :questions="painQuestionsPage.questions[selectedPainArea]"
                  :fullResults="results"
                  v-model="results.pain.areas[selectedPainArea]"
                  painQuestionCanBeZero
                />
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-row
      v-if="page === 2"
      :class="highZoom ? 'contentStyleHighZoom' : 'contentStyle'"
      justify="center"
      align="center"
      style="min-height: 300px"
      class="pa-4"
      no-gutters
    >
      <v-col id="redFlagsPage" cols="12" class="text-center" v-if="hasRedFlags">
        <v-row justify="center" no-gutters>
          <v-img
            src="@/assets/images/warning.png"
            :height="$vuetify.breakpoint.xs ? '100px' : '150px'"
            contain
          />
        </v-row>
        <p class="text-h5 my-3">
          {{ $t("painCheckin.pages.2.redFlags.title") }}
        </p>
        <p class="text-body-1">
          {{ $t("painCheckin.pages.2.redFlags.description") }}
        </p>
        <v-row justify="center" no-gutters>
          <v-col cols="auto" align-self="center" class="my-3">
            <v-btn
              color="primary"
              rounded
              :width="$vuetify.breakpoint.xs ? '200px' : '375px'"
              @click="completeAssessment()"
              >{{ $t("buttons.close") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col id="generatingPage" v-else cols="12" class="text-center text-h5">
        <v-row justify="center" no-gutters>
          <dots-loader
            :size="120"
            :color="$vuetify.theme.currentTheme.primary"
          />
        </v-row>
        <p class="text-h5 mt-6">{{ $t("painCheckin.pages.2.updating") }}</p>
      </v-col>
    </v-row>

    <v-row v-if="page < 2" no-gutters justify="center" class="py-4">
      <v-col
        class="px-2"
        cols="8"
        sm="3"
        md="2"
        v-if="page > 0"
        :class="$vuetify.breakpoint.xs ? 'mb-2' : ''"
        ><v-btn
          color="primary"
          outlined
          rounded
          @click="goToPage(0)"
          width="100%"
          >{{ $t("buttons.back") }}</v-btn
        ></v-col
      >
      <v-col class="px-2" cols="8" sm="3" md="2" v-if="page < 1"
        ><v-btn
          id="continueButton"
          color="primary"
          rounded
          @click="goToPage(1)"
          width="100%"
          :disabled="!selectedPainArea"
          >{{ $t("buttons.continue") }}</v-btn
        ></v-col
      ><v-col class="px-2" cols="8" sm="3" md="2" v-else
        ><v-btn
          id="submitButton"
          color="primary"
          rounded
          @click="submit()"
          width="100%"
          :loading="saving"
          >{{ $t("buttons.submit") }}</v-btn
        ></v-col
      >
      <v-col v-if="showError" cols="12">
        <p class="text-center error--text mb-0 mt-2">{{ errorMessage }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import WellnessGoalImages from "@/assets/json/wellness/WellnessGoalImages.json";
import PainCheckinQuestions from "@/assets/json/wellness/PainCheckinQuestions.json";
import FormGenerator from "@/components/form/FormGenerator.vue";
import PainPerson from "@/components/painareas/PainPerson";
import DatapointGeneration from "@/services/assessment-results-datapoint-generation";
import {
  storeLatestPainInfo,
  updateWellnessProgrammeWithPain
} from "@/customApi.js";
import { EventBus } from "@/services/events/event-bus";
import svgRegionsFemale from "@/components/painareas/PainAreasFemale.json";
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import SummaryService from "@/services/deskassessment/desk-assessment-summary";
import DotsLoader from "@/components/common/loaders/DotsLoader.vue";
import * as Constants from "@/constants/constants.js";

export default {
  name: "PainCheckin",
  components: {
    PainPerson,
    FormGenerator,
    DotsLoader
  },
  props: {
    existingPainArea: String
  },
  data() {
    return {
      page: 0,
      results: { pain: { gender: "female", areas: {} } },
      selectedPainArea: "",
      svgPainAreaName: "",
      saving: false,
      loaderTimeLeft: 2500,
      timerInterval: null,
      errorMessage: this.$t("painCheckin.error"),
      showError: false
    };
  },
  created() {
    if (this.existingPainArea) {
      // need to set this in created prior to painPerson being loaded
      this.results.pain.areas[this.existingPainArea] = {};
    }
  },
  mounted() {
    if (this.existingPainArea) {
      // All of this info needs to be set in order for pain person to behave as expected
      this.selectedPainArea = this.existingPainArea;
      this.svgPainAreaName = this.$t(
        svgRegionsFemale[this.existingPainArea].name
      );
      this.$refs.painPerson.currentSelection = this.existingPainArea;
    }
  },
  computed: {
    highZoom() {
      return window.devicePixelRatio > 1;
    },
    titleTextClass() {
      return this.highZoom ? "text-h6" : "text-h5";
    },
    subTitleTextClass() {
      return this.highZoom ? "text-body-2" : "text-body-1";
    },
    painQuestionsPage() {
      return PainCheckinQuestions[0].pages[0];
    },
    painAreaImage() {
      return WellnessGoalImages.ReducePain[this.selectedPainArea];
    },
    painAreaText() {
      return this.$t(
        `wellness.v2.statsCard.painAreas.${this.selectedPainArea}`
      ).toLowerCase();
    },
    existingAreaText() {
      if (this.existingPainArea) {
        return this.$t(
          `wellness.v2.statsCard.painAreas.${this.existingPainArea}`
        ).toLowerCase();
      } else if (this.selectedPainArea) {
        return this.$t(
          `wellness.v2.statsCard.painAreas.${this.selectedPainArea}`
        ).toLowerCase();
      }

      return "";
    },
    hasRedFlags() {
      let areas = Object.keys(this.results.pain.areas);
      if (areas.length === 0) {
        return false;
      }

      let area = areas[0];
      return DeskAssessmentUtil.hasRedFlags(
        area,
        this.results.pain.areas[area],
        Constants.assessmentTypes.checkIn
      );
    }
  },
  methods: {
    async submit() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        EventBus.$emit("validateCustomFormComponents");
        let questionPage = document.getElementById("questionPage");
        questionPage.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

        return;
      }

      try {
        this.showError = false;
        this.startGenerationTimer();
        this.saving = true;
        let datapoints = this.generateAssessmentDataPointDto();
        var assessmentId = await storeLatestPainInfo(datapoints);
        EventBus.$emit("setPainCoachCycleToLoad");
        var painAreas = SummaryService.GetRankedPainAreaDtosOrDefault(
          this.results
        );
        updateWellnessProgrammeWithPain(assessmentId, painAreas)
          .then(response => {
            EventBus.$emit("updatePainCoachCycle");
            if (this.loaderTimeLeft <= 0) {
              this.completeAssessment();
            }
          })
          .catch(ex => {
            this.$logger.captureException(ex);
          });
        this.saving = false;
      } catch (ex) {
        this.showError = true;
        this.page = 1;
        this.$logger.captureException(ex);
      }
    },
    startGenerationTimer() {
      this.page = 2;

      if (this.hasRedFlags) {
        this.$emit("hideCloseButton");
        return;
      }

      this.timerInterval = setInterval(() => {
        this.loaderTimeLeft -= 10;
        if (this.loaderTimeLeft <= 0 && !this.saving) {
          clearInterval(this.timerInterval);
          this.completeAssessment();
        }
      }, 10);
    },
    completeAssessment() {
      EventBus.$emit("updatePainStats");
      this.$emit("close");
    },
    generateAssessmentDataPointDto() {
      let assessmentDataPointValues = [];

      for (const [key, value] of Object.entries(this.results.pain.areas)) {
        for (const [key1, value1] of Object.entries(value)) {
          DatapointGeneration.getAssessmentDataPointValuesForPain(
            1,
            PainCheckinQuestions,
            key,
            key1,
            value1,
            assessmentDataPointValues
          );
        }
      }

      return assessmentDataPointValues;
    },
    goToPage(index) {
      this.page = index;
    }
  }
};
</script>

<style scoped>
.circle {
  width: 60px;
  height: 60px;
  border-radius: 99em;
  box-shadow: inset 0 0 0 1px var(--v-vitrueDarkGrey-base);
}

.contentStyle {
  height: 85%;
  overflow-y: auto;
}

.contentStyleHighZoom {
  height: 80%;
  overflow-y: auto;
}

.modalStyle {
  overflow: auto;
  height: 65vh;
}
</style>
