var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "fill-height",
      class: _vm.page < 2 ? "modalStyle" : "",
      attrs: { rounded: "xl", flat: "" }
    },
    [
      _vm.page === 0
        ? _c(
            "v-row",
            {
              class: _vm.highZoom ? "contentStyleHighZoom" : "contentStyle",
              attrs: {
                id: "painPersonPage",
                justify: "center",
                "no-gutters": ""
              }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "my-4 text-center",
                  class: _vm.titleTextClass,
                  attrs: {
                    id: "painPersonHeading",
                    cols: "10",
                    lg: _vm.highZoom ? "11" : "8"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("painCheckin.pages.0.heading")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "vitrueDarkGrey--text text-center",
                  class: _vm.subTitleTextClass,
                  attrs: {
                    id: "painPersonInstructions",
                    cols: "10",
                    lg: _vm.highZoom ? "11" : "8"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("painCheckin.pages.0.instructions", {
                        0: _vm.existingAreaText
                      })
                    )
                  )
                ]
              ),
              _c(
                "v-col",
                { staticClass: "my-4", attrs: { cols: "10" } },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "center",
                        align: "center",
                        "no-gutters": ""
                      }
                    },
                    [
                      _c("v-col", { attrs: { cols: "4", order: "0" } }),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "6",
                            md: "4",
                            order: _vm.$vuetify.breakpoint.mdAndUp ? "1" : "2"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              this.svgPainAreaName
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass:
                                        "text-body-1 ma-2 white--text",
                                      attrs: {
                                        id: "painAreaChip",
                                        color: "vitrueDarkGrey",
                                        small: !_vm.highZoom,
                                        "x-small": _vm.highZoom
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.svgPainAreaName))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            md: "4",
                            order: _vm.$vuetify.breakpoint.mdAndUp ? "2" : "1"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: _vm.$vuetify.breakpoint.mdAndUp
                                  ? "end"
                                  : "center",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "elevation-0 primary--text text-body-1",
                                  attrs: {
                                    small: !_vm.highZoom,
                                    "x-small": _vm.highZoom,
                                    tile: "",
                                    color: "selectedPrimary",
                                    outlined: _vm.results.pain.gender !== "male"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.results.pain.gender = "male"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("painCheckin.pages.0.masculine")
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "elevation-0 primary--text text-body-1",
                                  attrs: {
                                    small: !_vm.highZoom,
                                    "x-small": _vm.highZoom,
                                    tile: "",
                                    color: "selectedPrimary",
                                    outlined:
                                      _vm.results.pain.gender !== "female"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.results.pain.gender = "female"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("painCheckin.pages.0.feminine")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { xs: "8", sm: "6", md: "3", lg: "3" } },
                        [
                          _c("pain-person", {
                            ref: "painPerson",
                            attrs: {
                              oneAreaOnly: "",
                              hideChips: "",
                              hideGenderChange: "",
                              selectedColor:
                                _vm.$vuetify.theme.currentTheme.brightError,
                              assessmentType: "PainCheckIn"
                            },
                            on: {
                              genderChanged: function($event) {
                                _vm.results.pain.gender = $event
                              },
                              selectionChanged: function($event) {
                                _vm.selectedPainArea = $event
                              },
                              selectionNameChanged: function($event) {
                                _vm.svgPainAreaName = $event
                              }
                            },
                            model: {
                              value: _vm.results.pain,
                              callback: function($$v) {
                                _vm.$set(_vm.results, "pain", $$v)
                              },
                              expression: "results.pain"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.page === 1
        ? _c(
            "div",
            {
              class: _vm.highZoom ? "contentStyleHighZoom" : "contentStyle",
              attrs: { id: "questionPage" }
            },
            [
              _c(
                "v-row",
                {
                  attrs: { justify: "center", align: "start", "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-4 text-center",
                      class: _vm.titleTextClass,
                      attrs: { cols: "10" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-img",
                                {
                                  staticClass: "rounded-circle",
                                  attrs: {
                                    id: "painQuestionCircleImage",
                                    width: "60",
                                    height: "60",
                                    src: require("@/assets/images/" +
                                      _vm.painAreaImage)
                                  }
                                },
                                [_c("div", { staticClass: "circle" })]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: { id: "painQuestionHeading", cols: "auto" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("painCheckin.pages.1.heading", {
                                    0: _vm.painAreaText
                                  })
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.lgAndUp
                        ? "pl-16 ml-16"
                        : "",
                      attrs: {
                        cols: "10",
                        md: _vm.highZoom ? "10" : "8",
                        lg: "8"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "11" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  attrs: { onsubmit: "return false;" }
                                },
                                [
                                  _c("form-generator", {
                                    attrs: {
                                      questions:
                                        _vm.painQuestionsPage.questions[
                                          _vm.selectedPainArea
                                        ],
                                      fullResults: _vm.results,
                                      painQuestionCanBeZero: ""
                                    },
                                    model: {
                                      value:
                                        _vm.results.pain.areas[
                                          _vm.selectedPainArea
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.results.pain.areas,
                                          _vm.selectedPainArea,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "results.pain.areas[selectedPainArea]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.page === 2
        ? _c(
            "v-row",
            {
              staticClass: "pa-4",
              class: _vm.highZoom ? "contentStyleHighZoom" : "contentStyle",
              staticStyle: { "min-height": "300px" },
              attrs: { justify: "center", align: "center", "no-gutters": "" }
            },
            [
              _vm.hasRedFlags
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { id: "redFlagsPage", cols: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/images/warning.png"),
                              height: _vm.$vuetify.breakpoint.xs
                                ? "100px"
                                : "150px",
                              contain: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text-h5 my-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("painCheckin.pages.2.redFlags.title")
                            ) +
                            " "
                        )
                      ]),
                      _c("p", { staticClass: "text-body-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("painCheckin.pages.2.redFlags.description")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "my-3",
                              attrs: { cols: "auto", "align-self": "center" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    rounded: "",
                                    width: _vm.$vuetify.breakpoint.xs
                                      ? "200px"
                                      : "375px"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.completeAssessment()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("buttons.close")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h5",
                      attrs: { id: "generatingPage", cols: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c("dots-loader", {
                            attrs: {
                              size: 120,
                              color: _vm.$vuetify.theme.currentTheme.primary
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text-h5 mt-6" }, [
                        _vm._v(_vm._s(_vm.$t("painCheckin.pages.2.updating")))
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _vm.page < 2
        ? _c(
            "v-row",
            {
              staticClass: "py-4",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _vm.page > 0
                ? _c(
                    "v-col",
                    {
                      staticClass: "px-2",
                      class: _vm.$vuetify.breakpoint.xs ? "mb-2" : "",
                      attrs: { cols: "8", sm: "3", md: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            outlined: "",
                            rounded: "",
                            width: "100%"
                          },
                          on: {
                            click: function($event) {
                              return _vm.goToPage(0)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.back")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.page < 1
                ? _c(
                    "v-col",
                    {
                      staticClass: "px-2",
                      attrs: { cols: "8", sm: "3", md: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "continueButton",
                            color: "primary",
                            rounded: "",
                            width: "100%",
                            disabled: !_vm.selectedPainArea
                          },
                          on: {
                            click: function($event) {
                              return _vm.goToPage(1)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.continue")))]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    {
                      staticClass: "px-2",
                      attrs: { cols: "8", sm: "3", md: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "submitButton",
                            color: "primary",
                            rounded: "",
                            width: "100%",
                            loading: _vm.saving
                          },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.submit")))]
                      )
                    ],
                    1
                  ),
              _vm.showError
                ? _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "text-center error--text mb-0 mt-2" },
                      [_vm._v(_vm._s(_vm.errorMessage))]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }